import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from 'api_service';
import { Globals } from 'base';
import { Guest } from 'models/guest';
import { Module } from 'models/module';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { delay, filter, take } from 'rxjs/operators';
import { GuestService } from 'services/guest.service';

@Injectable({providedIn: 'root'})
export class FeedbackService implements OnDestroy {
  subscriptions: Subscription = new Subscription();
  stepsSub: Subscription;
  guest: Guest;
  value: number;

  public ready = new BehaviorSubject<boolean>(false);
  open_modal = this.ready.asObservable();

  constructor(private api: ApiService,
              private globals: Globals,
              private guestService: GuestService) {
  }

  getQuestions(): Observable<Object> {
    return this.api.get('feedback/entries');
  }

  // Smart Feedback
  initSubscriptions() {
    return new Promise((resolve, reject) => {
      this.subscriptions.add(this.guestService.currentGuest.pipe(filter(Boolean), take(1)).subscribe((guest: Guest) => {
        if (guest.smart_feedback) {
          this.value = Number(guest.smart_feedback.trigger_value);
          if (guest.smart_feedback.trigger === 'time') {
            this.smart_feedback_timer();
          } else if (guest.smart_feedback.trigger === 'clicks') {
            this.smart_feedback_steps();
          }
          resolve(true);
        } else {
          reject()
        }
      }));
    })
  }

  smart_feedback_timer() {
    // wait for defined time, then show modalService
    const value = this.value * 1000;
    of(true).pipe(delay(value)).subscribe(() => {
      this.ready.next(true);
    });
  }

  smart_feedback_steps() {
    // wait until guest has reached enough visits, then show modalService
    this.stepsSub = this.subscriptions.add(this.guestService.steps.subscribe((steps: Number) => {
      if (steps === this.value) {
        this.ready.next(true);
        this.stepsSub?.unsubscribe();
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
