import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DomModal } from 'models/dom_modal';
import { A2hsService } from 'services/a2hs.service';
import { ModalService } from '../modal/modal.service';

@Injectable({providedIn: 'root'})
export class FooterService {
  openSubj: BehaviorSubject<boolean> = new BehaviorSubject(null);
  openObservable: Observable<boolean> = this.openSubj.asObservable();

  constructor() {}
}
