<form #form="ngForm" class="redesign">
  <fieldset>
    <ng-container *ngIf="terminal">
      <h2 class="title main-text">{{'misc.room_number' | translate}} : <span class="door-number">{{door?.name}}</span></h2>
      <p class="text" translate>pms_door.pin_codes.name</p>
      <div class="divider"></div>
    </ng-container>

    <div class="pin-preview-grid">
      <div>
        <h3 class="main-text" translate>{{ codeTitle }}</h3>
        <span class="pin">{{door?.start_code}} <span *ngIf="sag">✔️</span>️</span>
      </div>
      <div *ngIf="sag">
        <h3 class="main-text" translate>pms_door.pin_codes.comfort_code</h3>
        <span class="pin">{{door?.comfort_code}} <span *ngIf="sag">✔️</span>️</span>
      </div>
    </div>

    <div class="button-grid" *ngIf="terminal && !hideFinishButton">
      <button class="btn-filled" (click)="close()" translate>misc.finish</button>
    </div>
  </fieldset>
</form>

<ng-template #noCodes>
  <div class="alert alert-danger" translate>pms_door.pin_codes.error</div>
</ng-template>

<ng-template #loading>
  <app-loader [small]="true"></app-loader>
</ng-template>
