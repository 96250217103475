import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Globals } from 'base';

@Injectable({providedIn: 'root'})
export class BackgroundService implements OnDestroy {
  private subscriptions: Subscription = new Subscription();

  bgSubj: Subject<any>;
  bgSubscription: Subscription;
  medias: any[];

  constructor(private globals: Globals) { }

  loadBackground(medias) {
    this.bgSubscription?.unsubscribe();
    if (!this.globals.kiosk()) {
      this.medias = medias;

      if (<any>this.medias && <any>this.medias.length === 1) {
        if ((<any>this.medias)[0].media.type === 'video') {
          this.playVideo();
        }
      } else {
        this.playVideo().then(() => {
          this.backgroundInterval(this.mediaDuration());
        });
      }
    }
  }

  backgroundInterval(timeout = 15000): any {
    const bg = document.getElementById('background');
    this.bgSubj = new Subject();

    this.subscriptions.add(
      this.bgSubscription = this.bgSubj.pipe(
        switchMap((_timeout: number = timeout) => timer(_timeout, _timeout))
      ).subscribe(() => {
        const current = bg.querySelector('.active');
        const next = bg.querySelector('.active + div') || bg.children[0];

        next.classList.add('active');
        current.classList.remove('active');

        if (next.getAttribute('data-type') === 'video' || next.getAttribute('data-type') !== current.getAttribute('data-type')) {
          this.resetBgInterval(this.mediaDuration());
        }
        this.playVideo();
        const video = current.querySelector('video');
        if (video) {
          video.pause();
          video.currentTime = 0;
        }
      }));
    this.bgSubj.next(timeout);
  }

  resetBgInterval(timeout = 15000) {
    this.bgSubj.next(timeout);
  }

  playVideo(): Promise<void> {
    return new Promise<void>((resolve, _reject) => {
      $(function () {
        const bg = document.getElementById('background');
        const current = bg?.querySelector('.active');
        if (current?.querySelector('video')) {
          current.querySelector('video').muted = true;

          const promise = current.querySelector('video').play();
          if (promise !== undefined) {
            promise.catch(() => { /* Auto-play was prevented */ });
          }
        }
        resolve();
      });
    });
  }

  mediaDuration() {
    const bg = document.getElementById('background');

    return parseInt(bg.querySelector('.active')?.getAttribute('data-duration'), 10) || 15000;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
