import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GenericCheckIn } from 'pms_models/generic_check_in';
import { GenericCheckOut } from 'pms_models/generic_check_out';
import { ApiService } from 'api_service';

@Injectable({providedIn: 'root'})
export class PmsService {
  passportAuthSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  passportAuth: Observable<boolean> = this.passportAuthSubj.asObservable();

  incident: GenericCheckIn | GenericCheckOut;
  wizard = false;

  constructor(private api: ApiService) {
  }

  get_reservation(uuid, module): Observable<Object> {
    if (uuid === undefined || uuid === null) {
      uuid = '';
    }
    return this.api.get(`pms/${module}?uuid=${uuid}`);
  }

  get_texts(module, uuid = '') {
    return this.api.silentGet(`countries/${module}?uuid=${uuid}`);
  }

  documentIsValid(image, group): Observable<Object> {
    return this.api.post('pms/check_in', {image: image, group: group});
  }

  get_reservation_by(data): Observable<Object> {
    return this.api.post('pms/login', data);
  }

  getReservationByQr(data): Observable<Object> {
    return this.api.post('pms/login/qr', data);
  }

  /** internal number **/
  getReservationById(id: number): Observable<Object> {
    return this.api.get('pms/reservation/' + id);
  }

  getServices(only_booked, uuid): Observable<Object> {
    return this.api.get(`pms/services?booked=${only_booked}&uuid=${uuid}`);
  }

  bookAdditionalService(rule_id, id, origin, params): Observable<Object> {
    return this.api.post('pms/services', {id: id, rule_id: rule_id, origin: origin, params: params});
  }

  getReservationInfo(): Observable<Object> {
    return this.api.get('pms/reservation');
  }

  getFolios(uuid, token = '', mod = ''): Observable<Object> {
    if (uuid === undefined) {
      uuid = '';
    }
    return this.api.get(`pms/folios?uuid=${uuid}&token=${token}&mod=${mod}`);
  }

  setCityTax(remove: boolean): Observable<Object> {
    return this.api.silentPost('pms/folios/', {remove: remove});
  }

  logout(): Observable<Object> {
    return this.api.delete('pms/reservation/logout', {});
  }

  /**
   * START Payment
   **/

  getPaymentData(folio_id): Observable<Object> {
    return this.api.get(`pms/payment/${folio_id}`);
  }

  makePayment(data): Observable<Object> {
    return this.api.post('pms/payment', data);
  }

  paymentDetails(data): Observable<Object> {
    return this.api.post('pms/payment/details', data);
  }

  checkPayment(reservationId): Observable<Object> {
    return this.api.get(`pms/payment/check/${reservationId}`);
  }

  // END #############################

  /**
   * START Caching
   **/

  getOnlineCache(token) {
    return this.api.get('pms/cache/' + token);
  }

  setOnlineCache(data): Observable<Object> {
    return this.api.post('pms/cache', data);
  }

  cacheImageUpload(type, uuid, image) {
    return this.api.post(`pms/cache/image/${type}/${uuid}`, {image: image});
  }

  // END #############################

  changeReservation(uuid): Observable<Object> {
    return this.api.put(`pms/reservation/${uuid}`, {});
  }

  // CI CO

  getAuthUrl(uuid): Observable<Object> {
    return this.api.get(`pms/check_in/auth_url?uuid=${uuid}`);
  }

  clear(): void {
    this.incident = null;
  }
}
