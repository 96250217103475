import { Directive, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PmsCiCoService } from 'cico_service';
import { Field } from 'models/field';
import { ConfirmName, PmsModType, Step } from 'pms_enums';
import { GenericData } from 'pms_models/generic_data';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { StepperService } from 'services/pms/stepper.service';

@Directive()
export class PmsBaseDirective implements OnInit, OnDestroy {
  protected subscriptions: Subscription = new Subscription();
  protected index: number;
  public ciCoType = PmsModType;
  data: GenericData;
  confirmName: string;
  ownInvoiceAddress: any;
  fellows: boolean;

  @ViewChild('form', {static: false}) form: NgForm;

  constructor(protected cicoService: PmsCiCoService,
              protected step?: Step,
              protected route?: ActivatedRoute,
              protected stepperService?: StepperService) {
  }

  ngOnInit(): void {
    this.cicoService.confirmNameSubj.next(ConfirmName.next);
    this.cicoService.disableNextButton(false);
    this.cicoService.hideNextButton(this.cicoService.activeStep?.key === Step.confirm);
    this.cicoService.hideBackButton(false);
    this.cicoService.preventNext = false;
    this.fetchData();
  }
  
  /**
   * Automatically navigate to the next step if the current step is not the one that should be skipped.
   * This will call each guest step and invoice step
   */
  autoNextProcess(step: Step) {
    setTimeout(() => {
      const autoSkipUntilStep = this.cicoService.autoSkipUntilStep;
      const navigate = autoSkipUntilStep && this.cicoService.activeStep.key == step;
      if (navigate) {
        this.stepperService.nextStep();
      } else {
        const clearAutoSkip = this.cicoService.autoSkipUntilStep === this.cicoService.activeStep.key && this.cicoService.activeStep.key !== Step.guests;
        if (clearAutoSkip) {
          this.cicoService.autoSkipUntilStep = undefined;
        }
      }
    });
  }

  start() {
    this.cicoService.infoScreen = false;
    this.cicoService.hideNextButton(false);
    this.cicoService.suppressGuardSubj.next(false);
  }

  protected fetchData() {
    this.subscriptions.add(this.cicoService.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
      this.data = data;
    }, () => {}));
  }

  modelFor(id) {
    return this.data.incident?.field_values?.find(field => field.id === id);
  }

  field_for(id, sub = null): Field {
    const result = this.data?.module?.field(id) || this.cicoService.field_for(id);
    return sub == null ? result : result?.fields?.find(field => field.identifier === sub);
  }

  setAdultCounter() {
    this.data.incident.reservation.persons = this.data.incident.reservation.related_guests.length + 1;
  }

  invoiceAddressPosition() {
    const display = this.data.module.settings.invoice_address;
    this.ownInvoiceAddress = display === 'own_tab' && this.field_for('invoice_address')?.active;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
