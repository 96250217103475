import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { UserActionType } from 'pms_enums';
import { of, Subscription, timer } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import { EncoderService } from 'services/websocket/encoder.service';

@Component({
  selector: 'app-pms-encoder',
  templateUrl: './encoder.component.html',
  styleUrls: ['./encoder.component.scss']
})

export class PmsEncoderComponent implements OnInit, OnDestroy {

  ROMAN_NUMERALS: string[] = ['I', 'II', 'III', 'IV', 'V'];

  private subscriptions: Subscription = new Subscription();
  isLost: boolean;
  loaded: boolean;
  encoding: boolean;
  encodeText = 'encode';
  firstSuccess = false;
  remaining_keycards: any;
  journey: string;
  systemType: string;
  finishSubscription: Subscription;

  startProcces: boolean = false;

  @Input() confirmation: any;
  @Input() encoderData: any;

  constructor(private cicoService: PmsCiCoService,
              private encoderService: EncoderService,
              public globals: Globals) {
  }

  ngOnInit(): void {
    this.isLost = this.encoderData.isLost === 'lost';
    this.encodeText = this.encoderData.already_encoded && !this.isLost ? 'encode_further' : 'encode';
    this.cicoService.cardEncoded = this.encoderData.already_encoded;

    this.journey = this.confirmation ? 'check_in' : 'stay';
    this.setSystemType();

    this.globals.getModule('pms_wizard', false).then(mod => {
      this.cicoService.toggleInactivity(true);

      this.encoderService.getInfo(this.encoderData.uuid).subscribe((data: any) => {
        if (this.isLost && data.remaining_keycards !== null) {
          this.remaining_keycards = data.total_keycards;
        } else {
          this.remaining_keycards = data.remaining_keycards;
        }
        this.loaded = true;
      }, () => {});

      this.subscriptions.add(this.encoderService.result.subscribe((result: any) => {
        this.informGuest(result);
      }));
    });
  }

  generateKey(): void {
    if (this.remaining_keycards !== null && this.remaining_keycards === 0) {
      return;
    }
    this.globals.clearAlert();
    this.cicoService.toggleInactivity(false);

    of(true).pipe(delay(1), take(1)).subscribe(() => {
      this.encodeText = this.firstSuccess ? 'encoding_further' : 'encoding';
      this.encoding = true;
    });

    const data = {
      id: this.encoderData.uuid,
      lost_key: this.isLost,
      journey: this.journey,
      further_isLost: this.encoderData.further_isLost
    };

    this.finished();
    this.encoderService.createCard(data).subscribe((result: any) => {
      this.remaining_keycards = result.remaining_keycards;
      this.informGuest(result);
    }, (e) => {
      this.encoding = false;
      this.cicoService.toggleInactivity(true);
      this.encodeText = this.firstSuccess ? 'encode_further' : 'encode';

      if (this.inView()) {
        if (e.status === 400) {
          this.cicoService.cardEncoded = true;
          this.globals.alert('info', this.globals.translate('pms_door.encoder.error.limit'));
        } else {
          this.globals.alert('error', this.globals.translate('pms_door.encoder.error.general'), this.globals.translate('misc.error'));
        }
      }
    });
  }

  finished() {
    const maxAttempts = 10;
    let attempts = 0;
    this.finishSubscription = timer(10000, 6000).pipe(take(maxAttempts + 1)).subscribe(() => {
      if (this.encoding && attempts < maxAttempts) {
        this.encoderService.getStatus(this.encoderData.uuid).subscribe((result: any) => {
          if (result) {
            this.informGuest(result);
          }
        });
        attempts++;
      } else if (this.inView()) {
        this.informGuest({error: this.globals.translate('pms_door.encoder.error.general')});
      }
    });
  }

  informGuest(result) {
    if (!this.encoding) {
      return;
    }

    this.encoding = result.state === 'pending';
    if (!this.encoding) {
      this.finishSubscription?.unsubscribe();
      this.cicoService.toggleInactivity(true);
    }
    if (result.error) {
      this.globals.alert('error', result.error);
    } else {
      this.cicoService.suppressGuardSubj.next(true);
      this.firstSuccess = true;
      if (this.isLost) {
        this.isLost = false;
        this.encoderData.isLost = 'duplicate';
        this.encoderData.further_isLost = true;
      }
      const state = result.state === 'pending' ? 'pending' : 'done';
      const text = `pms_door.encoder.success.${state}`;
      this.globals.alert('success', this.globals.translate(`${text}.description`), this.globals.translate(`${text}.title`));
    }
    this.cicoService.cardEncoded = true;
    this.encodeText = this.firstSuccess ? 'encode_further' : 'encode';
  }

  setSystemType() {
    if (this.globals.place.view === 'hardware_terminal') {
      this.systemType = 'hardware_kiosk';
    } else if (this.globals.business.tech.door_system.type === 'four_suites') {
      this.systemType = 'four_suites';
    } else {
      this.systemType = 'global';
    }
  }

  inView(): boolean {
    return !!document.getElementById('card_encoder');
  }

  start() {
    this.startProcces = true;
  }

  finish() {
    if (this.cicoService.cardEncoded) {
      this.cicoService.suppressGuardSubj.next(true);
    }
    this.cicoService.infoScreen = true;
    this.cicoService.navigate(UserActionType.cancel);
    this.cicoService.headerActionSubj.next(UserActionType.cancel);
  }

  ngOnDestroy(): void {
    this.cicoService.infoScreen = false;
    this.finishSubscription?.unsubscribe();
    this.subscriptions.unsubscribe();
  }
}
