import { AnimationBuilder } from '@angular/animations';
import { Component, Input, EventEmitter, Output, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { UserActionType, OverlayAction, PmsModType, ConfirmName, OverlayType, Step } from 'pms_enums';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { PassportPhotoService } from 'services/passport-photo.service';
import { GenericData } from 'models/pms/generic_data';
import { CiCoFooterBaseDirective } from '../../base/cico-footer-base-directive';
import { StepperService } from 'services/pms/stepper.service';

@Component({
  selector: 'app-pms-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class PmsFooterComponent extends CiCoFooterBaseDirective implements OnInit, OnDestroy {
  data: GenericData;
  pagination: any;
  showOverlay = false;
  showCamera: boolean = false;

  @Input() currentIndex: number;
  @Input() index: number;
  @Input() generalIndex: number;
  @Input() reservationIndex: number;
  @Input() confirmName: string;
  @Input() showBack: boolean;
  @Input() showNext: boolean = Boolean(false);
  @Input() disableNext: boolean;
  @Input() disableBack: boolean;
  @Input() nextDisplayText: string = String('Next');
  @Input() Icon: 'start' | 'refresh';
  @Input() idnow: boolean;
  @Input() cus: boolean;

  @Output() nextClicked = new EventEmitter<void>();
  @Output() prevClicked = new EventEmitter<void>();
  reservationStep = 0;

  constructor(public cicoService: PmsCiCoService,
              public passportPhotoService: PassportPhotoService,
              private _cdr: ChangeDetectorRef,
              public globals: Globals,
              protected animationBuilder: AnimationBuilder,
              public stepperService: StepperService) {
    super(globals, cicoService, animationBuilder);
    this.cicoService.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
      this.data = data;
    });
  }

  ngOnInit() {
    super.ngOnInit();

    this.subscriptions.add(this.passportPhotoService.cameraVisible$.subscribe(visible => {
      this.showCamera = visible;
      if (!this.showCamera) {
        this._cdr.detectChanges();
      }
    }));

    this.subscriptions.add(this.cicoService.overlayOpen.subscribe((value) => {
      setTimeout(() => {
        this.showOverlay = value;
        this._cdr.detectChanges();
      });
    }));

    // Subscribes to the 'overlayClose' event emitted by the cicoService. This event is fired when any overlay is closed.
    this.subscriptions.add(
      this.cicoService.overlayClose.subscribe((event: any) => {
        // Check if the action associated with the overlay close events
        if (event.action === OverlayAction.addToFolios) {
          // If the closed overlay was related to adding cross and upsell to folios, proceed to the next step in the stepper.
          // This is typically triggered after a user confirms an action on a reservation upsell or cross-sell modal.
          this.stepperService.nextStep();
        }
      })
    );

    this.subscriptions.add(this.cicoService.keyboardOpenSubj.subscribe(open => {
      this.keyboardOpen = open;
      if (this.globals.isIos()) {
        this.handleKeyboardVisibility(this.keyboardOpen);
      }
    }));

    window.addEventListener('scroll', () => {
      if (this.globals.isIos() && this.keyboardOpen) {
        this.handleKeyboardVisibility(this.keyboardOpen);
      }
    });
  }

  nextIcon() {
    return this.pagination ? this.index < this.pagination.length : true;
  }

  /** Handles the 'previous' button click in a UI stepper component.*/
  onPreviousClick() {
    if (this.showOverlay) {
      this.cicoService.closeOverlay(OverlayAction.close);
      return;
    }
    this.stepperService.previousStep()
  }

  /** Handles the 'next' button click in a UI stepper component.*/
  onNextClick() {
    // Check if the current confirmation name includes 'add'
    if (this.confirmName.includes(ConfirmName.add_product)) {
      // If 'add' is included, trigger a popup for a cross-selling opportunity
      this.popUpOverlayForCrossUpsell();
    } else {
      // If 'add' is not included, proceed to the next step in the stepper
      this.stepperService.nextStep();
    }
  }

  /** Updates the overlay for a cross upsell event based on the current user's steps and service bookings. */
  private popUpOverlayForCrossUpsell(): void {
    const guestsUserSteps = this.cicoService.userSteps.find(step => step.key === Step.guests);
    const serviceBookingsCount = guestsUserSteps?.totalSteps * this.cicoService.totalNights;

    // Calculate the index for product selection by excluding the first step (reservation step),
    // then adjusting for zero-based array index.
    const reservationStepIndex = this.cicoService.activeStep.currentStep - 2;

    // Check if the calculated product index is within the valid range.
    if (reservationStepIndex >= 0 && reservationStepIndex < this.cicoService.rule.products.length) {
      const currentProductName = this.cicoService.rule.products[reservationStepIndex].product.name;
      const title = `${serviceBookingsCount} X ${currentProductName}`;
      this.cicoService.openOverlay(OverlayType.addToFolios, title, undefined, undefined, this.cicoService.totalGrossService);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
