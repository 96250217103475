import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable({providedIn: 'root'})
export class AwayService {
  timer: Object;

  constructor() {
  }

  start_timer() {
    _initJQuery();

    this.timer = new Object({break_time: 0, start_date: new Date().getTime()});
    // Away after 1,5 minutes
    setAwayTimeout(90000, this);

    return this.timer;
  }

  stop_timer(timer) {
    if (iframe()) {
      return this.time(timer);
    } else {
      return this.time(timer) - timer.break_time;
    }
  }

  // Unix-Time is in milliseconds, but we need nanoseconds
  time(timer) {
    return (<any>new Date().getTime() - <any>timer.start_date) / 1000;
  }
}

let _timer: Object;
let _this = null;
let _awayNow = false;
let _awayTimestamp = null;
let _awayTimer = null;
let _awayTimeout = null;

function setAwayTimeout(a, self) {
  _this = self;
  _timer = self.timer;
  _awayTimeout = a;
  _awayTimestamp = new Date().getTime() + a;
  if (_awayTimer != null) {
    clearTimeout(_awayTimer);
  }
  _awayTimer = setTimeout(_makeAway, a + 50);
}

function _makeAway() {
  const a = new Date().getTime();
  if (a < _awayTimestamp) {
    _awayTimer = setTimeout(_makeAway, _awayTimestamp - a + 50);
    return;
  }
  _awayNow = true;
  away();
}

function _active() {
  const a = new Date().getTime();
  _awayTimestamp = a + _awayTimeout;
  if (_awayNow) {
    setAwayTimeout(_awayTimeout, _this);
  }
  if (_awayNow) {
    back();
  }
  _awayNow = false;
}

function _initJQuery() {
  $(document);
  const events = ['mousedown', 'mouseenter', 'mousemove', 'scroll', 'mouseup', 'click', 'dblclick', 'keypress', 'change', 'focusin', 'focusout', 'input', 'keydown', 'keyup', 'mouseout', 'mouseover', 'touchend', 'touchmove', 'touchstart'];
  $(function () {
    events.forEach(event => {
      $(this).on(event, function () {
        _active();
      });
    });
  });
}

function away() {
  _this.start_break = new Date().getTime();
}

function back() {
  _this.current_break = (<any>new Date().getTime() - _this.start_break) / 1000;
  _timer['break_time'] += _this.current_break;
  _this.start_break = null;
}

function iframe() {
  if (document.getElementsByClassName('sharemag')[0]) {
    return false;
  } else {
    return document.getElementsByClassName('iframe_container')[0] || document.getElementsByClassName('agm-map.overview')[0];
  }
}
