<app-radio-group [inputId]="'product'+index" [(ngModel)]="cicoService.productsBooking.toBeBooked" [description]="'cus.breakfast'| translate : {productName: cicoService.rule.products[index].product.name}" (ngModelChange)="logBreakfast(index)" [options]="[['misc.yes_string', true],['misc.no_string', false]]"></app-radio-group>

<div *ngIf="images.length" class="carousel-container">
  <div class="carousel">
    <div class="arrow left-arrow">
      <svg class="icon primary-stroke" width="16" height="16" viewBox="0 0 16 16" fill="none" (click)="prevImg()">
        <path d="M6.38086 3.95312L2.33419 7.99979L6.38086 12.0465" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M13.666 8H2.44602" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>

    <img [src]="images[0].media.url" alt="Carousel Image" (click)="photoClick(images[0].media.url)">

    <div class="arrow right-arrow">
      <svg class="icon primary-stroke" width="16" height="16" viewBox="0 0 16 16" fill="none" (click)="nextImg()">
        <path d="M9.61914 3.95312L13.6658 7.99979L9.61914 12.0465" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M2.33398 8H13.554" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  </div>

  <div *ngIf="images.length > 2" class="additional-images" [ngClass]="images.length == 3 ? 'grid-two-pics' : 'grid-three-pics'">
    <img *ngFor="let img of images | slice:1" [src]="img.media.url" [alt]="img.alt" (click)="photoClick(img.media.url)">
  </div>
</div>

<div class="price">
  {{product.gross | currency:product.currency:true}}
</div>

<p class="description" *ngIf="product.description?.length">{{product.description}}</p>
<div class="info">
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <circle cx="12" cy="12" r="11.5" fill="white" class="main-stroke" />
    <path d="M11 18H13.4V10.8H11V18ZM12.2 8.4C12.54 8.4 12.8252 8.2848 13.0556 8.0544C13.286 7.824 13.4008 7.5392 13.4 7.2C13.4 6.86 13.2848 6.5748 13.0544 6.3444C12.824 6.114 12.5392 5.9992 12.2 6C11.86 6 11.5748 6.1152 11.3444 6.3456C11.114 6.576 10.9992 6.8608 11 7.2C11 7.54 11.1152 7.8252 11.3456 8.0556C11.576 8.286 11.8608 8.4008 12.2 8.4Z" class="main-fill" />
  </svg>

  <div translate>cus.info</div>
</div><!-- <span class="see-more main-text">See More</span> -->
<app-photo-preview class="animation-out" *ngIf="showPreview" [photoSrc]="previewSrc" (close)="showPreview = false"></app-photo-preview>
