<div class="info-screen">
  <svg class="primary-stroke" *ngIf="type === 'info'" viewBox="0 0 278 278" fill="none">
    <path class="main-stroke" d="M139 272.5C65.3938 272.5 5.5 212.606 5.5 139C5.5 65.3938 65.3938 5.5 139 5.5C212.606 5.5 272.5 65.3938 272.5 139C272.5 212.606 212.606 272.5 139 272.5Z" fill="white" stroke="#008FDF" stroke-width="11"/>
    <path class="main-fill" d="M125 186C125 193.732 131.268 200 139 200V200C146.732 200 153 193.732 153 186V130C153 122.268 146.732 116 139 116V116C131.268 116 125 122.268 125 130V186Z" fill="#008FDF"/>
    <path class="main-fill" d="M139 106C146.732 106 153 99.7322 153 92C153 84.2678 146.732 78 139 78C131.268 78 125 84.2678 125 92C125 99.7322 131.268 106 139 106Z" fill="#008FDF"/>
  </svg>
  <svg class="primary-stroke" *ngIf="type === 'success'" viewBox="0 0 278 278" fill="none">
    <path d="M139 272.5C65.3938 272.5 5.5 212.606 5.5 139C5.5 65.3938 65.3938 5.5 139 5.5C212.606 5.5 272.5 65.3938 272.5 139C272.5 212.606 212.606 272.5 139 272.5Z" fill="white" stroke="#00DFCA" stroke-width="11"/>
    <path class="main-fill" d="M126.843 179.374C123.284 182.887 118.48 184.848 113.481 184.83C108.481 184.813 103.691 182.817 100.158 179.28L68.6799 145.888C63.4757 140.684 64.7868 133.563 70.1803 128.547C75.2899 123.627 83.2683 125.628 88.1887 130.548L113.5 155.718L174.345 94.8734C179.454 89.7636 187.687 89.7636 192.797 94.8734L193.175 95.2519C198.285 100.362 198.285 108.689 193.081 113.799L126.843 179.374Z" fill="#00DFCA"/>
  </svg>
  <svg class="primary-stroke" *ngIf="type === 'error'" viewBox="0 0 278 278" fill="none">
    <path class="main-stroke" d="M139 272.5C65.3938 272.5 5.5 212.606 5.5 139C5.5 65.3938 65.3938 5.5 139 5.5C212.606 5.5 272.5 65.3938 272.5 139C272.5 212.606 212.606 272.5 139 272.5Z" fill="white" stroke="#DA0000" stroke-width="11"/>
    <path class="main-fill" d="M87.7785 170.43C82.3784 175.831 82.3784 184.586 87.7785 189.986V189.986C93.1787 195.386 101.934 195.386 107.334 189.986L189.987 107.333C195.387 101.933 195.387 93.178 189.987 87.7778V87.7778C184.587 82.3777 175.831 82.3777 170.431 87.7778L87.7785 170.43Z" fill="#DA0000"/>
    <path class="main-fill" d="M108.084 88.0313C102.684 82.6311 93.9285 82.6311 88.5283 88.0313V88.0313C83.1282 93.4314 83.1282 102.187 88.5283 107.587L171.165 190.223C176.565 195.623 185.32 195.623 190.72 190.223V190.223C196.12 184.823 196.12 176.068 190.72 170.667L108.084 88.0313Z" fill="#DA0000"/>
    <path class="main-stroke" d="M139 272.5C65.3938 272.5 5.5 212.606 5.5 139C5.5 65.3938 65.3938 5.5 139 5.5C212.606 5.5 272.5 65.3938 272.5 139C272.5 212.606 212.606 272.5 139 272.5Z" stroke="#DA0000" stroke-width="11"/>
  </svg>
  <svg class="primary-stroke" *ngIf="type === 'warning'" viewBox="0 0 278 278" fill="none">
    <path class="main-stroke" d="M139 5.5C212.606 5.5 272.5 65.3938 272.5 139C272.5 212.606 212.606 272.5 139 272.5C65.3938 272.5 5.5 212.606 5.5 139C5.5 65.3938 65.3938 5.5 139 5.5Z" fill="white" stroke="#F5DBB3" stroke-width="11"/>
    <path class="main-fill" d="M153 92C153 84.268 146.732 78 139 78V78C131.268 78 125 84.268 125 92V148C125 155.732 131.268 162 139 162V162C146.732 162 153 155.732 153 148V92Z" fill="#F5DBB3"/>
    <path class="main-fill" d="M139 172C131.268 172 125 178.268 125 186C125 193.732 131.268 200 139 200C146.732 200 153 193.732 153 186C153 178.268 146.732 172 139 172Z" fill="#F5DBB3"/>
  </svg>
  <span [innerHTML]="safeHtml"></span>
</div>
