<div class="content">
  <form #form="ngForm" class="redesign">
    <div class="entries-container">
      <ng-container *ngFor="let step of ['card', 'button', 'encode', 'success']; let i = index">
        <div class="menu entries">
          <div class="step-circle main-border"><span class="main-text">{{ROMAN_NUMERALS[i]}}</span></div>
          <ng-container *ngIf="['card', 'button'].includes(step); else general">
            <p class="step-description">{{'pms_door.encoder.steps.' + systemType + '.' + step | translate}}</p>
          </ng-container>
          <ng-template #general>
            <p class="step-description">{{'pms_door.encoder.steps.' + step | translate}}</p>
            <p [innerHTML]="'pms_door.encoder.steps.limit' | translate: {limit: remaining_keycards}" class="card-limit-info" *ngIf="step === 'encode' && remaining_keycards"></p>
            <p *ngIf="step === 'encode' && remaining_keycards === 0" class="card-limit-info" translate>pms_door.encoder.error.limit</p>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </form>

  <div class="btn-grid" *ngIf="loaded">
    <div id="buttons">
      <button class="btn-filled" [class.small-width]="firstSuccess" [disabled]="encoding" (click)="generateKey()" *ngIf="remaining_keycards !== 0">
        {{'pms_door.encoder.' + encodeText | translate}}
      </button>
      <button [class]="firstSuccess && remaining_keycards !== 0 ? 'btn-outline' : 'btn-filled'" *ngIf="(firstSuccess && !encoding) || remaining_keycards === 0" [class.small-width]="firstSuccess" [disabled]="encoding" (click)="finish()" translate>misc.finish</button>
    </div>
  </div>
</div>
