import { Component, Input, OnInit } from '@angular/core';
import { PmsCiCoService } from 'cico_service';
import { ConfirmName, Step } from 'pms_enums';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  @Input() product: any;
  @Input() index = 0;

  images: any[] = [];
  previewSrc: any;
  showPreview: boolean = false;

  constructor( public cicoService: PmsCiCoService,private currencyPipe: CurrencyPipe ) { }

  ngOnInit(): void {
    this.images = this.product.medias;
  }

  nextImg() {
    const lastElement = this.images.pop();
    this.images.unshift(lastElement);
  }

  prevImg() {
    const firstElement = this.images.shift();
    this.images.push(firstElement);
  }

  photoClick(photo: any) {
    this.showPreview = true;
    this.previewSrc = photo;
  }

  logBreakfast(i) {
    if (this.cicoService.productsBooking.toBeBooked) {
      this.cicoService.totalGrossService = this.transformAmount(this.totalPrice(i), this.cicoService.rule.products[i].product.currency);
      this.cicoService.confirmNameSubj.next(ConfirmName.add_product);
    } else {
      this.cicoService.confirmNameSubj.next(ConfirmName.next);
    }
  }

  transformAmount(amount: number, currencyCode: string): string | null {
    return this.currencyPipe.transform(amount, currencyCode, 'symbol', '1.2-2');
  }

  totalPrice(productIndex: number): number {
    // Attempt to find the total steps for guests. If not found, default to 1 to avoid multiplication by undefined.
    const totalSteps = this.cicoService.userSteps.find(step => step.key === Step.guests)?.totalSteps || 1;
  
    // Calculate the total price based on the product's gross value, total steps, and total nights.
    const productGross = this.cicoService.rule.products[productIndex].product.gross;
  
    return productGross * totalSteps * this.cicoService.totalNights;
  }
}
