import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ElementRef, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import * as moment from 'moment';
import { LocaleMask } from 'models/LocaleMask';
import { createMask } from '@ngneat/input-mask';
import { fromEvent, interval } from 'rxjs';
import { filter, take, takeWhile } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
  styleUrls: [
    '../input-styles.scss',
    './date-input.component.scss'
  ]
})

export class DateInputComponent implements OnInit, OnChanges {

  static LOCALE_MASKS: LocaleMask[] = [
    {
      locale: 'de',
      momentMask: 'DD.MM.YYYY',
      separator: '.'
    },
    {
      locale: 'en',
      momentMask: 'DD/MM/YYYY',
      separator: '/'
    },
    {
      locale: 'ar',
      momentMask: 'YYYY/MM/DD',
      separator: '/'
    },
    {
      locale: 'generic',
      momentMask: 'DD/MM/YYYY',
      separator: '/'
    }
  ];

  @Input() date: any;
  @Input() disabled = false;
  @Input() description: string;
  @Input() required: boolean;
  @Input() submitted: boolean;
  @Input() tooltip?: string;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() errorMsg: string;
  @Input() max: any;
  @Input() min: any;
  @Input() today: boolean;
  @Input() validation: string;
  @Output() dateChange = new EventEmitter<Date>();
  @ViewChild('dateNativeValue') dateNativeValue: ElementRef;

  inputId: string;
  mask: LocaleMask;
  inputValue: string;
  inputMaskConfig: any;
  initCompleted: boolean;
  asian = false;
  type = 'text';

  constructor(private businessService: BusinessService,
              private cicoService: PmsCiCoService,
              private globals: Globals) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.date && this.initCompleted && this.date !== this.inputValue) {
      this.handleNewExternalValue(this.date);
    }
    if (this.date == null && !changes['submitted']) {
      this.inputValue = '';
    }
  }

  ngOnInit(): void {
    this.inputId = `${this.name}_${Math.random().toString(36).substring(2)}`;
    
    if (this.isAsianLanguage() && !this.disabled) {
      this.asian = true;
      this.type = 'date';
      this.placeholder = 'YYYY-MM-DD';
    }
    if (!this.asian) {
      this.initMask();
    }

    if (this.disabled) {
      this.min = this.max = undefined;
    } else {
      this.setMinMaxDate();
    }

    this.initCompleted = true;
    this.handleNewExternalValue(this.date);
  }

  initMask() {
    this.businessService.currentLocale.subscribe(locale => {
      this.placeholder = this.placeholder || this.name;
      this.mask = this.getLocaleMask(locale === 'en' || locale === 'ar' || locale === 'de' ? locale : 'generic');

      if (!this.globals.place?.wizard) {
        this.listenToAutofill();
      }

      this.setMask();
    });
  }

  handleNewExternalValue(newValue: any) {
    if (newValue) {
      const format = this.mask?.momentMask || 'YYYY-MM-DD'
      if (moment(newValue).isValid()) {
        this.inputValue = moment(newValue).format(format);
      } else {
        this.inputValue = moment(newValue, format, true).format(format);
      }
    } else {
      this.inputValue = '';
    }
  }

  setMinMaxDate() {
    if (this.validation === 'no_validation') {
      return;
    }

    let minDate: Date;
    let maxDate: Date;

    if (this.validation) {
      switch (this.validation) {
        case 'future':
          minDate = new Date(this.min || this.globals.today());
          maxDate = new Date(this.max || this.globals.today(365 * 120));
          if (this.today) {
            minDate.setDate(minDate.getDate() - 1);
          }
          break;
        case 'past':
          minDate = new Date(this.min || this.globals.today());
          minDate.setFullYear(minDate.getFullYear() - 120);
          maxDate = new Date(this.max || this.globals.today());
          break;
        case 'adult':
          minDate = new Date(this.min || this.globals.today());
          minDate.setFullYear(minDate.getFullYear() - 120);
          maxDate = new Date(this.max || this.globals.today());
          maxDate.setFullYear(maxDate.getFullYear() - 18);
          break;
        case 'kids':
          minDate = new Date(this.min || this.globals.today());
          minDate.setFullYear(minDate.getFullYear() - 18);
          maxDate = new Date(this.max || this.globals.today());
      }
    }

    this.min = minDate ? minDate.toISOString().split('T')[0] : undefined;
    this.max = maxDate ? maxDate.toISOString().split('T')[0] : undefined;
  }

  setMask() {
    this.inputMaskConfig = createMask<string>({
      alias: 'datetime',
      inputFormat: this.mask.momentMask.toLowerCase(),
      groupSeparator: this.mask.separator,
      prefillYear: false,
      shiftPositions: false,
      showMaskOnHover: false,
      placeholder: this.mask.momentMask,
      insertMode: false
    });
  }

  onDateChange() {
    if (this.inputValue.length === 0) {
      this.inputValue = this.dateNativeValue.nativeElement.value;
    }

    let date: any;
    if (this.asian) {
      date = moment(this.inputValue).toDate();
    } else {
      date = moment(this.inputValue, this.mask.momentMask).toDate();
    }

    date = moment(date).format('YYYY-MM-DD');

    if (this.inputValue?.length === 10 && (this.asian || new RegExp(`^[0-9${this.mask.separator}]+$`).test(this.inputValue))) {
      if (date === 'Invalid date' || this.min > date || this.max < date) {
        this.dateChange.emit(null);
      } else {
        this.dateChange.emit(date);
      }
    } else {
      this.dateChange.emit(null);
      this.inputValue = '';
    }
  }

  listenToAutofill() {
    interval(10).pipe(takeWhile(() => this.dateNativeValue?.nativeElement), take(1)).subscribe(() => {
      fromEvent(this.dateNativeValue?.nativeElement, 'input').pipe(filter(Boolean), take(1)).subscribe((event: Event) => {
        if (this.containsMoreThanOneNumber((event.target as HTMLInputElement).value)) {
          this.dateNativeValue.nativeElement.blur();
          this.dateNativeValue.nativeElement.focus();
        }
      });
    });
  }

  getLocaleMask(locale: string): LocaleMask | undefined {
    return DateInputComponent.LOCALE_MASKS.find(mask => mask.locale === locale);
  }

  containsMoreThanOneNumber(value: string): boolean {
    return ((value.match(/\d+/g) || []).length > 1);
  }

  changeKeyboardState(open: boolean) {
    this.cicoService.keyboardOpenSubj.next(open);
  }

  isAsianLanguage(): boolean {
    const isChinese = /^zh([-_~!@#$%^&*()+=`[\]{}|;:'",<>/?]|$)/.test(navigator.language);
    const isJapanese = navigator.language === 'ja';
    const isKorean = navigator.language === 'ko';

    return isChinese || isJapanese || isKorean;
  }
}
