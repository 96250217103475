<app-basic-input *ngIf="fields.address?.active" ngDefaultControl name="address" [(ngModel)]="model.address.address" [description]="fields.address.name" [tooltip]="fields.address.description" [placeholder]="fields.address.name" [required]="fields.address.required" [errorMsg]="'validation.address'" [submited]="submited"></app-basic-input>

<div class="address-grid" [class.special]="states?.length">
  <app-basic-input *ngIf="fields.zipcode?.active" ngDefaultControl name="zipcode"  [(ngModel)]="model.address.zipcode" [description]="fields.zipcode.name" [tooltip]="fields.zipcode.description" [placeholder]="fields.zipcode.name" [required]="fields.zipcode.required" [errorMsg]="'validation.zip'" [submited]="submited"></app-basic-input>

  <app-basic-input *ngIf="fields.city?.active" ngDefaultControl name="city" [(ngModel)]="model.address.city" [description]="fields.city.name" [tooltip]="fields.city.description" [placeholder]="fields.city.name" [required]="fields.city.required" [errorMsg]="'validation.city'" [submited]="submited"></app-basic-input>

  <app-select *ngIf="states?.length" ngDefaultControl name="state" [(ngModel)]="model.address.state" [items]="states" [errorMsg]="'validation.state'" [required]="true" [description]="'address.state' | translate" [placeholder]="'address.state' | translate" [showIcons]="true"></app-select>

  <app-select *ngIf="fields.country?.active && data.countries?.length" ngDefaultControl name="'country'" [items]="data.countries" [errorMsg]="'validation.country'" [(ngModel)]="model.address.country" [required]="fields.country.required" [description]="fields.country.name" [tooltip]="fields.country.description" [placeholder]="'validation.selection' | translate" (ngModelChange)="countryChanged(model.address?.country)" [submited]="submited" [showIcons]="true"></app-select>
</div>
