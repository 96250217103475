import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InputValidatorModule } from '../../validators/input.validator.module';
import { DescriptionLabelComponent } from './input-fields/shared/description-label/description-label.component';
import { BasicInputComponent } from './input-fields/basic-input/basic-input.component';
import { ButtonGroupComponent } from './input-fields/button-group/button-group.component';
import { NumberValidatorModule } from '../../validators/number.validator.module';
import { CheckboxComponent } from './input-fields/checkbox/checkbox.component';
import { ClickOutsideDirective } from './input-fields/click-outside.directive';
import { DateInputComponent } from './input-fields/date-input/date-input.component';
import { FileInputComponent } from './input-fields/file-input/file-input.component';
import { SearchInputComponent } from './input-fields/search-input/search-input.component';
import { SelectComponent } from './input-fields/select/select.component';
import { PhoneInputComponent } from './input-fields/phone-input/phone-input.component';
import { DateValidatorModule } from '../../validators/date.validator.module';
import { LengthValidatorModule } from '../../validators/length.validator.module';
import { BooleanValidatorModule } from '../../validators/boolean.validator.module';
import { PhoneValidatorModule } from '../../validators/phone.validator.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { EmailValidatorModule } from 'validators/email.validator.module';
import { TextareaComponent } from './input-fields/textarea/textarea.component';
import { InputMaskModule } from '@ngneat/input-mask';
import { FileUploadComponent } from './input-fields/file-upload/file-upload.component';
import { TimeInputComponent } from './input-fields/time-input/time-input.component';
import { RadioGroupComponent } from './input-fields/radio-group/radio-group.component';

@NgModule({
  declarations: [
    DescriptionLabelComponent,
    BasicInputComponent,
    SelectComponent,
    DateInputComponent,
    PhoneInputComponent,
    CheckboxComponent,
    FileInputComponent,
    ButtonGroupComponent,
    SearchInputComponent,
    ClickOutsideDirective,
    TextareaComponent,
    TimeInputComponent,
    FileUploadComponent,
    RadioGroupComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LengthValidatorModule,
    TranslateModule,
    PhoneValidatorModule,
    TooltipModule,
    InputValidatorModule,
    EmailValidatorModule,
    DateValidatorModule,
    DateValidatorModule,
    NumberValidatorModule,
    InputMaskModule,
    BooleanValidatorModule
  ],
  exports: [
    DescriptionLabelComponent,
    BasicInputComponent,
    SelectComponent,
    DateInputComponent,
    PhoneInputComponent,
    CheckboxComponent,
    FileInputComponent,
    ButtonGroupComponent,
    SearchInputComponent,
    TextareaComponent,
    TimeInputComponent,
    FileUploadComponent,
    ClickOutsideDirective,
    RadioGroupComponent
  ]
})
export class TypographyModule {}
