import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Field } from 'models/field';
import { FieldValue } from 'models/field_value';
import { Module } from 'models/module';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { GenericData } from 'models/pms/generic_data';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-form-fields',
  templateUrl: './fields.component.html',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})

export class FormFieldsComponent implements OnInit {
  init: boolean;
  data: GenericData;

  @Input() form: any;
  @Input() submited: boolean = false;
  @Input() module: Module;
  @Input() useDefault = false;
  @Input() redesign = false;

  fields: Field[];

  constructor(public globals: Globals, private cicoService: PmsCiCoService) {}

  ngOnInit() {
    this.cicoService.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
      this.data = data;
    });

    this.initFields();
  }

  initFields() {
    this.fields = this.module.usableFields(this.useDefault);
    this.fields.forEach(field => {
      if (!this.modelFor(field.id)) {
        this.form.field_values.push(new FieldValue(field));
      }
    });
    this.init = true;
  }

  modelFor(id) {
    return this.form.field_values.find(field => field.id === id);
  }
}
