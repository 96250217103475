import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from 'base';
import { Business } from 'models/business';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { of, Subscription } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.scss']
})
export class SwiperComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  @Input() items: any[];
  @Input() lightbox = true;
  @Input() center = false;

  medias: any;
  slideIndex = 0;
  business: Business;

  autoplay = {
    delay: 7000,
    disableOnInteraction: false
  };

  public slideConfig: SwiperConfigInterface = {
    loop: true,
    watchOverflow: true,
    navigation: true,
    grabCursor: true
  };

  constructor(private sanitizer: DomSanitizer,
              private businessService: BusinessService,
              private globals: Globals) {
  }

  ngOnInit() {
    this.subscriptions.add(this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      if (this.items?.length) {
        this.business = business;
        this.medias = JSON.parse(JSON.stringify(this.items));
        this.medias.forEach((item: any) => {
          if (item.media.type === 'image') {
            item.media.url = this.sanitizer.bypassSecurityTrustUrl(item.media.url);
          } else {
            item.media.url.mp4 = this.sanitizer.bypassSecurityTrustUrl(item.media.url.mp4);
            item.media.url.webm = this.sanitizer.bypassSecurityTrustUrl(item.media.url.webm);
          }
        });

        if (<any>this.medias.length === 1) {
          this.slideConfig.loop = false;
        } else {
          if (business.style.image_swiper) {
            this.autoplay.delay = Number(business.style.image_trigger * 1000);
            this.slideConfig.autoplay = this.autoplay;
          } else {
            this.slideConfig.autoplay = false;
          }
        }
        if (this.medias.length > 0 && this.medias[this.slideIndex].media.type === 'video') {
          const self = this;
          $(function () {
            self.checkMedia(self.medias[self.slideIndex].media);
          });
        }
      }
    }));
  }

  swiperInit() {
    if (<any>document.querySelector('.swiper-slide-active')) {
      const videoEl = <any>document.querySelector('.swiper-slide-active').getElementsByTagName('video')[0];
      this.toggleVideoClass(videoEl);
    }
  }

  setIndex(index: number) {
    if (this.medias && this.medias[this.slideIndex]) {
      const current = this.medias[this.slideIndex].media;

      $('[data-id="' + current.id + '"]').each(function (_index, videoEl: any) {
        videoEl?.pause();
      });

      this.slideIndex = index;

      this.subscriptions.add(of(true).pipe(delay(100)).subscribe(() => {
        this.checkMedia(current);
      }));
    }
  }

  checkMedia(current: any) {
    if (this.medias[this.slideIndex]) {
      const next = this.medias[this.slideIndex].media;

      if (next.type === 'video' || next.type !== current.type) {
        if (this.business.style.image_swiper) {
          this.slideConfig.autoplay = {
            delay: (this.medias[this.slideIndex].media.duration || this.business.style.image_trigger) * 1000 - 100,
            disableOnInteraction: false
          };
        }

        if (next.settings.auto_start) {
          const self = this;
          $('[data-id="' + next.id + '"]').each(function (index, videoEl: any) {
            if (videoEl) {
              self.toggleVideoClass(videoEl);
              videoEl.play().catch(() => {});
            }
          });
        }
      }
    }
  }

  toggleVideoClass(videoEl: any) {
    ['prev', 'next'].forEach(button => {
      $(document.querySelector('.swiper-slide-active')).parent().parent().find('.swiper-button-' + button).toggleClass('video', !!videoEl);
    });
  }

  openItem(media, event) {
    if (event?.target && !event.target.className.includes('swiper-button') && media && media.type === 'image') {
      if (this.lightbox && !(this.globals.isMobile() || this.globals.isThin())) {
        this.openModal();
        this.showSlides(this.slideIndex);
      }
    }
  }

  openModal() {
    $('#swiperLightbox').css('display', 'block');
  }

  // Close the Modal
  closeModal() {
    $('#swiperLightbox').css('display', 'none');
    this.slideIndex = 0;
  }

  // Next/previous controls
  plusSlides(n) {
    this.showSlides(this.slideIndex += n);
  }

  // Thumbnail image controls
  currentSlide(n) {
    this.showSlides(this.slideIndex = n);
  }

  showSlides(n) {
    let i;
    const slides = $('.imgSlides');
    const dots = $('.demo');

    if (n === slides.length || n < 0) {
      this.slideIndex = 0;
    }

    for (i = 0; i < slides.length; i++) {
      $(slides[i]).css('display', 'none');
    }
    for (i = 0; i < dots.length; i++) {
      $(dots[i]).removeClass('active');
    }
    $(slides[this.slideIndex]).css('display', 'block');
    $(dots[this.slideIndex]).addClass('active');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
